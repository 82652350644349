.signup-email {
  height: 80rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));  
}

.signup-email .signup-email-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.signup-email .signup-email-content #loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.signup-email .signup-email-content .signup-email-main {
  width: 93%;
  height: 70rem;
  border-style: solid;
  border-color: var(--color-background); 
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-email .signup-email-content .signup-email-main h2 {
  font: 600 3rem barlow;
  color: var(--color-text-white);
  text-shadow: 2px 2px var(--color-text-dark-gray);
  margin-bottom: 3rem;
}

.signup-email .signup-email-content .signup-email-main .form-box {
  width: 35rem;
  height: 50rem;
  background: var(--color-background-white); 
  border: 0;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;  
}

.signup-email .signup-email-content .signup-email-main .form-box form {
  width: 31rem;
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;  
}

.signup-email .signup-email-content .signup-email-main .form-box form .signup-input {
  width: 100%;
  height: 4rem;  
  background: var(--color-background);
  margin-bottom: 1.5rem;
  border-style: solid;
  border-color: #bebebe;
  border-radius: 0.4rem;
  padding: 1rem;
  border-width: thin;
}

.signup-email .signup-email-content .signup-email-main .form-box form p {
  width: 31rem;
  margin-bottom: 0.5rem;
  font: 500 1.5rem barlow;
  color: var(--color-text-black);  
}

.signup-email .signup-email-content .signup-email-main .form-box form p a {
  color: var(--color-text-black);
  text-decoration-color: var(--color-text-black);
}

.signup-email .signup-email-content .signup-email-main .form-box form .button-box {
  width: 31rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem; 
}

.signup-email .signup-email-content .signup-email-main .form-box form .button-box strong {
  font: 500 1.5rem barlow;
  color: var(--color-text-black);  
}

.signup-email .signup-email-content .signup-email-main .form-box form .button-box a {
  text-decoration-color: var(--color-text-black);
}

.signup-email .signup-email-content .signup-email-main .form-box form .button-box button {
  width: 12rem;
  height: 3rem;
  background-image: linear-gradient(to right, var(--color-button-dark), var(--color-button-light));
  color: var(--color-button-text-light);
  border: 0;
  border-radius: 0.4rem;  
  cursor: pointer;
  font: 600 1.5rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;  
}

.signup-email .signup-email-content .signup-email-main .form-box form .button-box button:hover {
  color: var(--color-button-text-light-hover);
}

.signup-email .signup-email-content .signup-email-main .form-box .logo {
  width: 7rem;
  height: 7rem;
  margin-top: 2rem;
  margin-bottom: 2rem; 
}

.signup-email .signup-email-content .signup-email-main .form-box .logo img {
  width: 100%;
  height: 100%;
}

@media (min-width: 600px) {
  .signup-email .signup-email-content .signup-email-main .form-box {
    width: 44rem;
    height: 52rem;  
  }

  .signup-email .signup-email-content .signup-email-main .form-box form {
    width: 37rem;
    height: 38rem;   
  }

  .signup-email .signup-email-content .signup-email-main .form-box form p {
    width: 37rem;
    font: 500 1.8rem barlow;   
  }

  .signup-email .signup-email-content .signup-email-main .form-box form .button-box {
    width: 37rem;
  }

  .signup-email .signup-email-content .signup-email-main .form-box form .button-box strong {
    font: 500 1.8rem barlow;    
  }
}

@media (min-width: 1200px) {
  .signup-email {
    height: 90rem;    
  }

  .signup-email .signup-email-content .signup-email-main {    
    height: 80rem;  
  }

  .signup-email .signup-email-content .signup-email-main .form-box {
    width: 60rem;
    height: 55rem;
  }

  .signup-email .signup-email-content .signup-email-main .form-box form {
    width: 52rem;
    height: 40rem;       
  }

  .signup-email .signup-email-content .signup-email-main .form-box form p {
    width: 52rem;
    font: 500 1.8rem barlow;     
  }

  .signup-email .signup-email-content .signup-email-main .form-box form #termos {
    margin-top: 2rem;
  }

  .signup-email .signup-email-content .signup-email-main .form-box form .button-box {
    width: 52rem;
    margin-top: 4rem;
  }

  .signup-email .signup-email-content .signup-email-main .form-box form .button-box button {
    width: 20rem;
    height: 4.5rem;   
    font: 600 2rem barlow;   
  }

  .signup-email .signup-email-content .signup-email-main .form-box form .button-box strong {
    font: 500 2rem barlow;    
  }

  .signup-email .signup-email-content .signup-email-main .form-box .logo {
    width: 9rem;
    height: 9rem;    
  }
}

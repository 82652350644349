.signin {
  height: 190rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));  
}

.signin .signin-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.signin .signin-content .signin-box {
  width: 35rem;
  height: 70rem;
  background-image: linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light)); 
  border: 0;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.signin .signin-content .signin-box #loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.signin .signin-content .signin-box .form-logo {
  width: 90%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;  
}

.signin .signin-content .signin-box .form-logo .logo {
  width: 30.06rem;
  height: 18rem;  
}

.signin .signin-content .signin-box .form-logo .logo img {
  width: 100%;
  height: 100%;
}

.signin .signin-content .signin-box .form-logo h1 {
  font: 700 1.8rem barlow;
  color: var(--color-text-white);
  text-shadow: 1px 1px var(--color-text-blue);
  margin-bottom: 3rem;
}

.signin .signin-content .signin-box .form-logo strong {
  font: 600 1.8rem barlow;
  color: var(--color-text-black);   
}

.signin .signin-content .signin-box .form-separator {
  width: 100%;
  height: 0.5rem;
  background-image: linear-gradient(to right, var(--color-background-light), var(--color-background-login-dark)); 
}

.signin .signin-content .signin-box button {
  width: 28rem;
  height: 4rem;
  background-image: linear-gradient(to right, var(--color-button-dark), var(--color-button-light));
  color: var(--color-button-text-light);
  border: 0;
  border-radius: 0.4rem;  
  cursor: pointer;
  font: 600 1.5rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;
  margin-bottom: 1rem;
}

.signin .signin-content .signin-box button:hover {
  color: var(--color-button-text-light-hover);
}

.signin .signin-content .signin-box #link-button {
  text-decoration: none;
}

.signin .signin-content .signin-box .google {
  width: 28rem;
  height: 4.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3173de;
  border-radius: 0.4rem; 
}

.signin .signin-content .signin-box form {
  width: 28rem;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end; 
}

.signin .signin-content .signin-box form .signin-input {
  width: 100%;
  height: 4rem;  
  background: #FFFFFF;
  margin-bottom: 1.5rem;
  border-style: solid;
  border-color: #bebebe;
  border-radius: 0.4rem;
  padding: 1rem;
  border-width: thin;
}

.signin .signin-content .signin-box form strong {
  font: 400 1.5rem barlow;
  color: var(--color-text-black);
  margin-bottom: 1rem;
}

.signin .signin-content .signin-box form a {
  text-decoration-color: var(--color-text-black);
  margin-bottom: 1rem;  
}

.signin .signin-content .signin-box .secure-info {
  width: 28rem;
  height: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.signin .signin-content .signin-box .secure-info a {
  text-decoration-color: var(--color-text-black);
}

.signin .signin-content .signin-box .secure-info img {
  width: 2.8rem;
  height: 2.8rem;
}

.signin .signin-content .signin-box .secure-info strong {
  font: 600 1.7rem barlow;
  color: var(--color-text-black);
  margin-left: 1rem;
}

.signin .signin-content .main-box {
  width: 32rem;
  height: 100rem;  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center; 
}

.signin .signin-content .main-box .ingrid-foto {
  width: 18rem;
  height: 18rem;
  margin-bottom: 2rem; 
}

.signin .signin-content .main-box .ingrid-foto img {
  width: 100%;
  height: 100%;
}

.signin .signin-content .main-box .ingrid-signature {
  width: 25rem;
  height: 5.8rem;
  margin-top: 4rem; 
}

.signin .signin-content .main-box .ingrid-signature img {
  width: 100%;
  height: 100%;
}

.signin .signin-content .main-box strong {
  font: 600 2rem barlow;
  color: var(--color-text-white);
  margin-bottom: 3rem;
}

.signin .signin-content .main-box p {
  font: 500 1.5rem barlow;
  color: var(--color-text-white);
  text-align: center;
  margin-bottom: 0.5rem;
}

@media (min-width: 600px) {
  .signin .signin-content .main-box {
    width: 48rem;   
  }

  .signin .signin-content .main-box strong {
    font: 600 2.8rem barlow;    
    margin-bottom: 3rem;
  }
  
  .signin .signin-content .main-box p {
    font: 500 1.7rem barlow;   
    margin-bottom: 0.5rem;
  }

  .signin .signin-content .main-box .ingrid-signature {
    width: 35rem;
    height: 8.17rem;   
  }
}

@media (min-width: 1200px) {
  .signin {
    height: 90rem;    
  }

  .signin .signin-content {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .signin .signin-content .signin-box {
    width: 50rem;
    height: 90rem;
  }

  .signin .signin-content .main-box {
    height: 90rem;   
  }

  .signin .signin-content .main-box .ingrid-signature {
    margin-bottom: 3rem;  
  }  
  
  .signin .signin-content .signin-box .form-logo {   
    height: 28rem;
  }

  .signin .signin-content .signin-box .form-logo .logo {
    width: 41.75rem;
    height: 25rem;   
  }  
  
  .signin .signin-content .signin-box .form-logo h1 {
    font: 700 2.2rem barlow;
    margin-bottom: 4rem;  
  }
  
  .signin .signin-content .signin-box .form-logo strong {
    font: 600 2.5rem barlow;   
  }

  .signin .signin-content .signin-box .form-separator {
    height: 0.8rem;    
  }
  
  .signin .signin-content .signin-box button {
    width: 40rem;
    height: 5.5rem;   
    font: 600 1.8rem barlow;   
    margin-bottom: 1.5rem;
  }

  .signin .signin-content .signin-box .google {
    width: 40rem;
    height: 5.5rem;    
  }
  
  .signin .signin-content .signin-box form {
    width: 40rem;
    height: 30rem;   
    margin-top: 3rem;
  }
  
  .signin .signin-content .signin-box form strong {
    font: 400 2rem barlow;     
  }
  
  .signin .signin-content .signin-box form a {   
    margin-bottom: 2rem;  
  }

  .signin .signin-content .signin-box form .signin-input {
    width: 40rem;
    height: 5.5rem;
    margin-bottom: 2rem;
  }
  
  .signin .signin-content .signin-box .secure-info {
    width: 40rem;
    height: 10rem;   
  }
  
  .signin .signin-content .signin-box .secure-info img {
    width: 3.8rem;
    height: 3.8rem;
  }
  
  .signin .signin-content .signin-box .secure-info strong {
    font: 400 2.2rem barlow;   
    margin-left: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .signin .signin-content {   
    justify-content: center;
  }

  .signin .signin-content .signin-box {
   margin-right: 5rem;
  }
  
  .signin .signin-content .main-box {
    width: 62rem;
    margin-left: 5rem; 
  }
  
  .signin .signin-content .main-box .ingrid-foto {
    width: 25rem;
    height: 25rem;
    margin-bottom: 2rem; 
  }   

  .signin .signin-content .main-box strong {
    font: 600 3rem barlow;    
    margin-bottom: 3rem;
  }
  
  .signin .signin-content .main-box p {
    font: 500 1.7rem barlow;   
    margin-bottom: 0.5rem;
  }  
}

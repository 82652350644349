.footer {
  width: 100%;
  height: 77rem;  
  /*background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light)); */
}

.footer .footer-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /*background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light)); */
}

.footer .footer-content .contacts{
  width: 100%;
  height: 72rem;
  background: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer .footer-content .contacts #truenumbers {
  width: 8rem;
  height: 8rem;
}

.footer .footer-content .contacts #truenumbers img {
  width: 100%;
  height: 100%;
}

.footer .footer-content .contacts #contato strong {
  font: 700 4.5rem barlow;
  color: var(--color-text-brown);
}

.footer .footer-content .contacts #contato p {
  font: 300 2.2rem barlow;
  color: var(--color-text-black);
  margin-bottom: 4rem;
}

.footer .footer-content .contacts .contact {
  display: flex;
  flex-direction: column;  
  align-items: center;
  margin-bottom: 2rem;  
}

.footer .footer-content .contacts .contact strong {
  font: 700 2.5rem barlow;
  color: var(--color-text-brown);
  margin-top: 1rem; 
}

.footer .footer-content .contacts .contact img {
  width: 4.5rem;
  height: 4.5rem;  
}

.footer .footer-content strong {
  font: 300 1.3rem barlow;
  color: var(--color-text-white); 
  text-align: center;
  margin-top: 1.5rem; 
}

@media (min-width: 600px) {
  .footer .footer-content strong {
    font: 300 1.5rem barlow;   
  }  
}

@media (min-width: 1200px) {
  .footer {
    height: 35rem;
  }  

  .footer .footer-content .contacts {
    height: 30rem;
    flex-direction: row;   
  }

  .footer .footer-content .contacts #truenumbers {
    width: 12rem;
    height: 12rem;
    margin-left: 5rem;
  }  

  .footer .footer-content .contacts #contato {
    width: 20rem;
    align-items: flex-start;    
    margin-bottom: 0;   
    margin-left: 5rem;
  }

  .footer .footer-content .contacts #contato strong {
    font: 700 5rem barlow;
  }

  .footer .footer-content .contacts .contact {
    width: 20rem;   
  }
   
  .footer .footer-content .contacts .contact img {
    width: 9rem;
    height: 9rem;
  }

  .footer .footer-content strong {
    font: 300 2rem barlow;   
  }  
}

@media (min-width: 1400px) {
  .footer .footer-content .contacts #truenumbers {
    width: 15rem;
    height: 15rem;
    margin-left: 7rem;
  }  

  .footer .footer-content .contacts #contato {
    margin-left: 7rem;
  }
}

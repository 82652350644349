#loader-page {
	width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.order {
  width: 32rem;
  height: 4rem;
  margin-bottom: 1rem;   
}

.order .order-content {
  width: 100%;
  height: 100%;
  margin: 0 auto; 
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-style: solid;
  border-color: var(--color-text-brown);
  border-radius: 0.7rem;
  background-image: linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light));
}

.order .order-content p {
  font: 600 1.4rem barlow;  
}

@media (min-width: 600px) {
  .order {
    width: 40rem;    
  }
}

@media (min-width: 1200px) {
  .order {
    width: 50rem;    
  }
}

#privacy-and-user-terms-page {
	width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; 
}

.terms-content {
  width: 98%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;  
}

.terms-content p {
  font: 400 1.5rem barlow;
  color: var(--color-text-white);
  margin-bottom: 2rem;
}

.terms-content h1 {
  font: 600 2.5rem barlow;
  color: var(--color-text-white);
  margin-bottom: 2rem;
  margin-top: 3rem;
}

.terms-content strong {
  font: 500 2rem barlow;
  color: var(--color-text-white);
  margin-bottom: 2rem;
}

.terms-content .update {
  color: var(--color-text-red);
}

.terms-content .itens {
  margin-bottom: 0.5rem;
  margin-left: 1rem;
}

.terms-content .after-itens {
  margin-top: 1.5rem;  
}

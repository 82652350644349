.orders-list {
  height: 72rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));  
}

.orders-list .orders-list-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.orders-list .orders-list-content #loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
}

.orders-list .orders-list-content .orders-list-main {
  width: 93%;
  height: 72rem; 
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.orders-list .orders-list-content .orders-list-main h2 {
  font: 600 3rem barlow;
  color: var(--color-text-white);
  text-shadow: 2px 2px var(--color-text-dark-gray);
  margin-bottom: 3rem;
}

.orders-list .orders-list-content .orders-list-main .list-box {
  width: 36rem;
  height: 60rem;
  background: var(--color-background); 
  border: 0;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;  
}

.orders-list .orders-list-content .orders-list-main .list-box .list {
  width: 35rem;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  
  overflow-y: auto ;
  overflow-x: hidden;
}

.orders-list .orders-list-content .orders-list-main .list-box .list strong {
  font: 600 2rem barlow;
  color: var(--color-text-blue);
}

.orders-list .orders-list-content .orders-list-main .button-box {
  width: 31rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 2rem; 
}

.orders-list .orders-list-content .orders-list-main .button-box a {
  text-decoration: none
}

.orders-list .orders-list-content .orders-list-main .button-box button {
  width: 12rem;
  height: 3rem;
  background-image: linear-gradient(to right, var(--color-button-dark), var(--color-button-light));
  color: var(--color-button-text-light);
  border: 0;
  border-radius: 0.4rem;  
  cursor: pointer;
  font: 600 1.5rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;  
}

.orders-list .orders-list-content .orders-list-main .button-box button:hover {
  color: var(--color-button-text-light-hover);
}

@media (min-width: 600px) {  
  .orders-list .orders-list-content .orders-list-main .list-box {
    width: 46rem;    
  }
  
  .orders-list .orders-list-content .orders-list-main .list-box .list {
    width: 43rem;    
  }
}

@media (min-width: 1200px) {
  .orders-list .orders-list-content .orders-list-main .list-box {
    width: 56rem;   
  }

  .orders-list .orders-list-content .orders-list-main .list-box .list {
    width: 53rem;    
  }
}

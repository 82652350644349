.password-recovery {
  height: 45rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background-light));  
}

.password-recovery .password-recovery-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.password-recovery .password-recovery-content #loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.password-recovery .password-recovery-content .password-recovery-main {
  width: 93%;
  height: 38rem;
  border-style: solid;
  border-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.password-recovery .password-recovery-content .password-recovery-main h2 {
  font: 600 2.5rem barlow;
  color: var(--color-text-white);
  text-shadow: 2px 2px var(--color-text-dark-gray);
}

.password-recovery .password-recovery-content .password-recovery-main .form-box {
  width: 35rem;
  height: 45%;
  background: var(--color-background-white);  
  border: 0;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
}

.password-recovery .password-recovery-content .password-recovery-main .form-box form {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
}

.password-recovery .password-recovery-content .password-recovery-main .form-box form .recovery-input {
  width: 100%;
  height: 4rem;  
  background: var(--color-background);
  margin-bottom: 1.5rem;
  border-style: solid;
  border-color: #bebebe;
  border-radius: 0.4rem;
  padding: 1rem;
  border-width: thin;
}

.password-recovery .password-recovery-content .password-recovery-main .form-box form p {
  margin-bottom: 0.5rem;
  font: 500 1.5rem barlow;
  color: var(--color-text-black);
}

.password-recovery .password-recovery-content .password-recovery-main .form-box form .button-box {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem; 
}

.password-recovery .password-recovery-content .password-recovery-main .form-box .button-box strong {
  font: 500 1.5rem barlow;
  color: var(--color-text-black);
}

.password-recovery .password-recovery-content .password-recovery-main .form-box .button-box a {
  text-decoration-color: var(--color-text-black);
}

.password-recovery .password-recovery-content .password-recovery-main .form-box form .button-box button {
  width: 12rem;
  height: 3rem;
  background-image: linear-gradient(to right, var(--color-button-dark), var(--color-button-light));
  color: var(--color-button-text-light);
  border: 0;
  border-radius: 0.4rem;  
  cursor: pointer;
  font: 600 1.5rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;   
}

.password-recovery .password-recovery-content .password-recovery-main .form-box form .button-box button:hover {
  color: var(--color-button-text-light-hover);
}

@media (min-width: 600px) {
  .password-recovery .password-recovery-content .password-recovery-main .form-box {
    width: 45rem;   
  }
}

@media (min-width: 1200px) {
  .password-recovery {
    height: 50rem;    
  }
}
